import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useStore } from 'vuex';
import { reactive } from 'vue';
import storeHeader from '../../components/storeHeader.vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      chosenAddressId: 1
    });
    const list = store.state.userAddress.map(item => {
      if (item.isUse) {
        data.chosenAddressId = item.id;
      }
      return {
        id: item.id,
        name: item.name,
        tel: item.tel,
        address: `${item.province}${item.city}${item.county}${item.addressDetail}`,
        isDefault: item.isDefault
      };
    });
    const disabledList = [{
      id: '3',
      name: '王五',
      tel: '1320000000',
      address: '浙江省杭州市滨江区江南大道 15 号'
    }];
    const onAdd = () => {
      router.push({
        path: '/addressedit',
        query: {
          type: 'add'
        }
      });
    };
    const onEdit = item => {
      router.push({
        path: '/addressedit',
        query: {
          id: item.id,
          type: 'edit'
        }
      });
    };
    const selectAddress = item => {
      store.commit('changeAddress', item);
    };
    return (_ctx, _cache) => {
      const _component_van_address_list = _resolveComponent("van-address-list");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(storeHeader, {
        title: "我的收货地址"
      }), _createVNode(_component_van_address_list, {
        modelValue: data.chosenAddressId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => data.chosenAddressId = $event),
        list: _unref(list),
        "disabled-list": disabledList,
        "disabled-text": "以下地址超出配送范围",
        "default-tag-text": "默认",
        onAdd: onAdd,
        onEdit: onEdit,
        onSelect: selectAddress
      }, null, 8, ["modelValue", "list"])]);
    };
  }
};